import { getStrapiImage } from '@/lib/api'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

const Contact: React.FC<Props<Contact>> = ({ data }) => {
  const { push } = useRouter()
  const [state, setState] = React.useState({
    name: '',
    number: '',
    email: '',
    message: '',
  })

  const onChange =
    (props: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [props]: e.target.value })
    }

  const submit = () => {
    push(
      `mailto:uptodatedevelopers@gmail.com?subject=UpDev Challenge - ${state.name}&body=${state.message}`
    )
  }

  return (
    <Stack sx={{ bgcolor: '#000', py: 8 }} id="contact">
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack spacing={4}>
              <Typography variant="h5" fontWeight={700} color="#fff">
                {data?.titre}
              </Typography>
              <Typography color="#d8d8d8">{data?.description}</Typography>

              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Stack sx={{ width: 1 }}>
                    <Typography color="#fff" gutterBottom>
                      {data?.formulaire?.nom}
                    </Typography>
                    <InputBase
                      onChange={onChange('name')}
                      value={state.name}
                      sx={{
                        px: 3,
                        py: 1,
                        borderRadius: 1,
                        bgcolor: '#212121',
                        width: 1,
                      }}
                    />
                  </Stack>
                  <Stack sx={{ width: 1 }}>
                    <Typography color="#fff" gutterBottom>
                      {data?.formulaire?.telephone}
                    </Typography>
                    <InputBase
                      onChange={onChange('number')}
                      value={state.number}
                      sx={{
                        px: 3,
                        py: 1,
                        borderRadius: 1,
                        bgcolor: '#212121',
                        width: 1,
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack>
                  <Typography color="#fff" gutterBottom>
                    {data?.formulaire?.email}
                  </Typography>
                  <InputBase
                    onChange={onChange('email')}
                    value={state.email}
                    sx={{
                      px: 3,
                      py: 1,
                      borderRadius: 1,
                      bgcolor: '#212121',
                      width: 1,
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography color="#fff" gutterBottom>
                    {data?.formulaire?.message}
                  </Typography>
                  <InputBase
                    onChange={onChange('message')}
                    value={state.message}
                    rows={3}
                    multiline
                    sx={{
                      px: 3,
                      py: 1,
                      borderRadius: 1,
                      bgcolor: '#212121',
                      width: 1,
                    }}
                  />
                </Stack>
              </Stack>

              <Button
                color="white"
                onClick={submit}
                variant="contained"
                sx={{ py: 1.2 }}
              >
                {data?.formulaire?.envoyer}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              sx={{ position: 'relative', width: 0.7, height: 1, mx: 'auto' }}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                alt="Updev Challenge"
                src={getStrapiImage(data?.media)}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
                // placeholder="blur"
                // blurDataURL={getStrapiImagePlaceholder(data?.media)}
                priority
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}

export default Contact
